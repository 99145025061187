var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Alert ")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,indexVal){return _c('li',{key:indexVal},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0)]),_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"1"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-img',{attrs:{"fluid":"","src":_vm.data.cover}})],1)]),_c('b-col',{staticClass:"my-auto",attrs:{"md":"9"}},[_c('h4',[_c('span',{staticStyle:{"color":"#7367f0"}},[_vm._v("name :")]),_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('br'),_c('h4',[_c('span',{staticStyle:{"color":"#7367f0"}},[_vm._v("SKU رمز : ")]),_vm._v(" "+_vm._s(_vm.data.sku)+" ")]),_c('br')])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"language","label-for":"language","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"language","state":_vm.languagSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.languageOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.languagSelect),callback:function ($$v) {_vm.languagSelect=$$v},expression:"languagSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"TranslationName","state":errors.length > 0 ? false : null},model:{value:(_vm.translations.name),callback:function ($$v) {_vm.$set(_vm.translations, "name", $$v)},expression:"translations.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"short-description"}},[_c('validation-provider',{attrs:{"name":"short-description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"short-description","state":errors.length > 0 ? false : null},model:{value:(_vm.translations.short_description),callback:function ($$v) {_vm.$set(_vm.translations, "short_description", $$v)},expression:"translations.short_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":"translationDescription","state":errors.length > 0 ? false : null},model:{value:(_vm.translations.description),callback:function ($$v) {_vm.$set(_vm.translations, "description", $$v)},expression:"translations.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }